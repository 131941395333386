import StoreView from '../components/StoreView'
import styles from './App.module.css'

export default function App() {
  return (
    <div className={styles.root}>
      <StoreView></StoreView>
    </div>
  )
}
